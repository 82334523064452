import moment from 'moment';

const DateFormatMixin = {
  methods: {
    getFormattedDate (date, dateFormat= "DD/MM/YYYY", from=null) {
      if (from)
        return moment(date, from).locale("fr").format(dateFormat);
      return moment(date).locale("fr").format(dateFormat);
    }
  }
};

export default DateFormatMixin;